import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//获取关注查询
export const getFollowageList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/follow/list",
  });

//导出关注查询
export const exportFollowageList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/follow/export`,
  });

//举报列表
export const getReportList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/report/list",
  });

//举报回复
export const reportReply = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/report/reply",
  });

//用户解冻
export const userFreeze = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/freeze",
  });

//获取黑名单列表
export const getBlackList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/black/list",
  });

//获取熟悉度亲密度
export const getPopularityList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/popularity/list",
  });

//导出熟悉度请密度
export const exportPopularityList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/popularity/export`,
  });

//用户操作列表
export const getEventList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/event/list",
  });

//用户操作列表下拉
export const getEventType = () =>
  request({
    method: "post",
    url: "/adm/supervise/user/event/type",
  });

//导出用户行为数据
export const exportEventList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/event/list`,
  });
